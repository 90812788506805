@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");

@font-face {
    font-family: "NotoSansCJKkr";
    src: url("./fonts/notosans/NotoSans-Medium.eot");
    src: url("./fonts/notosans/NotoSans-Medium.eot") format("embedded-opentype"), url("./fonts/notosans/NotoSans-Medium.woff") format("woff"), url("./fonts/notosans/NotoSans-Medium.otf") format("truetype");
}

@font-face {
    font-family: "NotoSansCJKkr-R";
    src: url("./fonts/notosans/NotoSans-Regular.eot");
    src: url("./fonts/notosans/NotoSans-Regular.eot") format("embedded-opentype"), url("./fonts/notosans/NotoSans-Regular.woff") format("woff"), url("./fonts/notosans/NotoSans-Regular.otf") format("truetype");
}
@font-face {
    font-family: "Glyphicons Halflings";
    src: url("./fonts/glyphicons/glyphicons-halflings-regular.eot");
    src: url("./fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/glyphicons/glyphicons-halflings-regular.woff2") format("woff2"), url("./fonts/glyphicons/glyphicons-halflings-regular.woff") format("woff"), url("./fonts/glyphicons/glyphicons-halflings-regular.ttf") format("truetype"), url("./fonts/glyphicons/glyphicons-halflings-regular.svg") format("svg");
}

.popover {
    display: none;
}

.CodeMirror {
    border: 1px solid #eee;
    height: auto;
}
/* 
.ReactModal__Content div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
} */

@media all and (min-width: 918px) {
    .ril__navButtonNext {
        background: rgba(255, 255, 255, 1) url("./Icon/ic_arrow_next.png") no-repeat center !important;
        background-size: 40px !important;
        border: 1px solid #222 !important;
    }

    .ril__navButtonPrev {
        background: rgba(255, 255, 255, 1) url("./Icon/ic_arrow_back.png") no-repeat center !important;
        background-size: 40px !important;
        border: 1px solid #222 !important;
    }

    .ril__navButtons {
        opacity: 1 !important;
    }
}
/* 
.ril__outer {
    z-index: 9999 !important;
} */

.react-pdf__Page__canvas {
    margin: 0 auto;
}

/*
@media only screen and (max-width: 918px) {
    .react-pdf__Page__canvas {
        width: 100% !important;
    }
} */
